import _defineProperty from "/tmp/build_3cfd2177/client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "/tmp/build_3cfd2177/client/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/toConsumableArray";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/* eslint-disable max-len */

/* eslint-disable object-curly-newline */

/*
 * This file is shared between the client and the server side
 * */
import { get, isArray } from 'lodash';
import { createEditorState, SelectionState, Modifier, convertToRaw, EditorState } from 'medium-draft-studio';
import mediumDraftExporter from 'medium-draft-studio/lib/exporter';
/*
 * Constants - START
 */

export var MONGO_OBJECTID_RE = /^[0-9a-fA-F]{24}$/;
export var GYMGLISH_API_DOMAIN = 'https://www.gymglish.com';
export var THROTTLE_PERIOD_MS = 5000; // TEST TYPES

export var CONJUGATION = 'conjugation';
export var DROPDOWN = 'dropdown';
export var CHECKBOX = 'checkbox';
export var FIB = 'fib';
export var RADIO = 'radio';
export var AUTOEVAL = 'autoeval'; // TEST TYPES COMMON COMBINATIONS

export var ALL_TEST_TYPES = [RADIO, FIB, CHECKBOX, DROPDOWN, AUTOEVAL, CONJUGATION];
export var WITH_BRACKETS = [FIB, DROPDOWN];
export var WITHOUT_BRACKETS = [CHECKBOX, RADIO];
export var EXTENDED_BRACKETS = [].concat(WITH_BRACKETS, [AUTOEVAL]);
export var RECEIVES_IDBRACKET = [].concat(_toConsumableArray(EXTENDED_BRACKETS), [CONJUGATION]);
export var MULTIPLE_ANSWERSETS = [].concat(WITH_BRACKETS, [CONJUGATION]);
export var MULTIPLE_CORRECT_ANSWERS = [FIB, CHECKBOX, CONJUGATION];
export var NO_EXPLANATION = [AUTOEVAL, CONJUGATION]; // ANSWER TYPES FOR TESTS OTHER THAN AUTOEVAL

export var CORRECT = 'correct';
export var INCORRECT = 'incorrect';
export var ALMOSTGOOD = 'almost';
export var IDONTKNOW = 'idontknow'; // ANSWER TYPES FOR AUTOEVAL TESTS

export var AUTOEVAL_NOREVIEW = 'autoeval_no_review';
export var AUTOEVAL_OK = 'autoeval_ok';
export var AUTOEVAL_UNSURE = 'autoeval_unsure';
export var AUTOEVAL_BAD = 'autoeval_bad'; // COMMON ANSWER TYPES COMBINATIONS

export var DEFAULT_ANSWERSET = [CORRECT, INCORRECT, IDONTKNOW];
export var AUTOEVAL_ANSWERSET = [AUTOEVAL_NOREVIEW, AUTOEVAL_OK, AUTOEVAL_UNSURE, AUTOEVAL_BAD]; // MEDIA TYPES

export var IMAGE = 'image';
export var AUDIO = 'audio';
export var VIDEO = 'video';
export var TEXT = 'text';
export var TEXT_EXTRACT = 'text-extract';
export var SUBTITLES = 'subtitles'; // MEDIA TYPES COMMON COMBINATIONS

export var COMMON_MEDIA_TYPES = [IMAGE, AUDIO, VIDEO]; // LAYOUT MODE (MEDIA BRACKET TEST)

export var LAYOUT_MODE_ORIGINAL = 'original';
export var LAYOUT_MODE_COLUMN = 'column';
export var LAYOUT_MODE_ROW = 'row'; // LAYOUT MODE COMMON COMBINATIONS

export var CUSTOM_LAYOUT_MODE = [LAYOUT_MODE_ROW, LAYOUT_MODE_COLUMN];
export var ALL_LAYOUT_MODES = [LAYOUT_MODE_ORIGINAL].concat(CUSTOM_LAYOUT_MODE); // CONTENT TYPE

export var LESSON = 'lesson';
export var TEST = 'test';
export var STORY = 'story';
export var MINILESSON = 'minilesson';
export var NUGGET = 'nugget';
export var GRAIN = 'grain';
export var UNIT = 'unit';
export var DESSERT = 'dessert';
export var BRAINSTORM = 'brainstorm'; // DIRECTIONAL SORTING EDITS

export var MOVE_DIRECTION_UP = 'move_direction_up';
export var MOVE_DIRECTION_DOWN = 'move_direction_down'; // PROJECT HASHES

export var BORBOLLON_HASH = 'Z71I0U';
export var WUNDERBLA_HASH = 'Tmcis';
export var MEMORABLE_HASH = 'Z13VMHn';
export var GYMCORSU_HASH = 'Xr2dN'; // PUBLICATION STATUS CONFIRGURATION

export var PUBLICATION_STATUS_TRANSLATING = 'translating'; // MAX NUMBER OF COVERS (MINOR AND MAJOR) PERMITED PER TEST

export var MAXIMUM_UNIT_NUMBER = 2;
export var COOKIE_PRODUCT_SOURCE_LANGUAGE = 'X-Product-source-lang';
export var COOKIE_PRODUCT_AVAILABLE_LANGUAGES = '-Product-available-langs';
export var serverConfig = {
  // Node.js app
  serverHost: process.env.SERVER_HOST || 'localhost',
  port: process.env.PORT || 5000,
  websocketHost: process.env.WEBSOCKET_HOST || 'localhost',
  websocketPort: process.env.WEBSOCKET_PORT || 3005,
  websocketBaseUrl: '/websocket',
  db: {
    development: {
      url: process.env.MONGODB_URI || process.env.DB_URI || 'mongodb://localhost:27017/gymglish-studio'
    },
    test: {
      url: process.env.MONGODB_URI || process.env.DB_URI || 'mongodb://localhost:27017/gymglish-studio'
    },
    production: {
      url: process.env.MONGODB_URI || process.env.DB_URI
    }
  },
  // API Gateway
  api: {
    // API URL to be used in the client-side code
    clientUrl: process.env.API_CLIENT_URL || '',
    // API URL to be used in the server-side code
    serverUrl: process.env.API_SERVER_URL || "http://".concat(process.env.SERVER_HOST || 'localhost', ":").concat(process.env.PORT || 5000),
    websocketUrl: process.env.WEBSOCKET_URL || "ws://".concat(process.env.WEBSOCKET_HOST || 'localhost', ":").concat(process.env.WEBSOCKET_PORT || 3005, "/websocket"),
    s3Url: "https://".concat(process.env.S3_BUCKET, ".s3.amazonaws.com"),
    s3Suffix: process.env.S3_PATH_SUFFIX,
    bucket: process.env.S3_BUCKET
  },
  // Authentication
  auth: {
    gymglish_secret: process.env.GYMGLISH_SECRET_ACCESS_KEY
  },
  logs: {
    api: {
      // Level here is according to morgan's typings
      // tiny | combined | common | short | default | dev
      level: process.env.LOG_LEVEL_API || 'common'
    }
  }
};
/*
 * Constants - END
 * */

/*
 * Misc - START
 */

export var isMongoObjectId = function isMongoObjectId(str) {
  return MONGO_OBJECTID_RE.test(str);
};
export var getApiUrl = function getApiUrl() {
  return "".concat(process.env.NODE_ENV === 'development' ? serverConfig.api.serverUrl : '', "/api");
};
export function isHTML(text) {
  return /<[a-z][\s\S]*>/i.test(text);
}
export function getJson(str) {
  // Return the JS object for a JSON if the string is valid.
  try {
    if (str.charAt(0) !== '{') {
      return null;
    }

    return JSON.parse(str);
  } catch (e) {
    return null;
  }
}
export function stripHtml(string) {
  return string ? decodeHtml(string.replace(/<[^>]+>/g, '')) : '';
}

function decodeHtml(string) {
  if (typeof window === 'undefined') return string;
  var doc = new DOMParser().parseFromString(string, 'text/html');
  return doc.documentElement.textContent;
}

export function isStringIncludedNormalized(shouldIncludeText, textToInclude) {
  var normalizedShouldIncludeText = shouldIncludeText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  var normalizedTextToInclude = textToInclude.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return normalizedShouldIncludeText.includes(normalizedTextToInclude);
}
/*
 * Misc - END
 */

/*
 * Helper functions for Draft.js - START
 */

function getEditorStateFromJSON() {
  var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var editorState;
  var draftData = isArray(text) ? text[0] : text;
  var draftContentJson = getJson(draftData);

  if (draftContentJson) {
    editorState = createEditorState(draftContentJson);
  } else {
    editorState = createEditorState();
  }

  return editorState;
}

export function getContentEntities(text, entityType) {
  var contentState = getEditorStateFromJSON(text).getCurrentContent();
  var entities = [];
  contentState.getBlocksAsArray().forEach(function (block) {
    var selectedEntity = null;
    block.findEntityRanges( // filter function
    function (character) {
      if (character.getEntity() !== null) {
        var entity = contentState.getEntity(character.getEntity());

        if (!entityType || entityType && entity.getType() === entityType) {
          selectedEntity = {
            entityKey: character.getEntity(),
            blockKey: block.getKey(),
            entity: contentState.getEntity(character.getEntity())
          };
          return true;
        }
      }

      return false;
    }, // callback function
    function (start, end) {
      entities.push(_objectSpread(_objectSpread({}, selectedEntity), {}, {
        start: start,
        end: end
      }));
    });
  });
  return entities;
}
export function removeSinglePlaceholderEntity(text) {
  var entityAtOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var blockKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  var direction = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '';
  var content = getEditorStateFromJSON(text).getCurrentContent();
  var entities = getContentEntities(text, 'PLACEHOLDER');
  var contentWithoutEntities = content;
  var removedIndex = null; // If no entity given, we cannot remove it!

  if (!entityAtOffset) {
    return {
      editorState: JSON.stringify(convertToRaw(content)),
      removedIndex: removedIndex
    };
  }

  for (var index = 0; index < entities.length; index++) {
    var entity = entities[index];
    var offset = direction === 'forward' ? entity.start : entity.end;

    if (entityAtOffset === offset && entity.blockKey === blockKey) {
      var selectionToRemove = new SelectionState({
        anchorKey: entity.blockKey,
        anchorOffset: entity.start,
        focusKey: entity.blockKey,
        focusOffset: entity.end
      });
      contentWithoutEntities = Modifier.removeRange(contentWithoutEntities, selectionToRemove, 'forward');
      removedIndex = index; // We removed the entity and can stop iterating.

      break;
    }
  }

  return {
    editorState: JSON.stringify(convertToRaw(contentWithoutEntities)),
    removedIndex: removedIndex
  };
}

function getContentEntitiesByBlock(text, entityType) {
  var contentState = getEditorStateFromJSON(text).getCurrentContent();
  var entitiesByBlock = {};
  contentState.getBlocksAsArray().forEach(function (block) {
    var selectedEntity = null;
    block.findEntityRanges( // filter function
    function (character) {
      if (character.getEntity() !== null) {
        var entity = contentState.getEntity(character.getEntity());

        if (!entityType || entityType && entity.getType() === entityType) {
          selectedEntity = {
            entityKey: character.getEntity(),
            blockKey: block.getKey(),
            entity: contentState.getEntity(character.getEntity())
          };
          return true;
        }
      }

      return false;
    }, // callback function
    function (start, end) {
      var blockKey = block.getKey();
      var entitiesOfBlock = entitiesByBlock[blockKey] || [];
      entitiesByBlock[blockKey] = [].concat(_toConsumableArray(entitiesOfBlock), [_objectSpread(_objectSpread({}, selectedEntity), {}, {
        start: start,
        end: end
      })]);
    });
  });
  return entitiesByBlock;
}

export function removePlaceholderEntities(text) {
  var contentWithoutEntities = getEditorStateFromJSON(text).getCurrentContent();
  var blocksWithEntities = getContentEntitiesByBlock(text, 'PLACEHOLDER');
  Object.values(blocksWithEntities).forEach(function (entities) {
    // Iterate through all entities of current block
    for (var index = 0; index < entities.length; index++) {
      var entity = entities[index];

      var _entity$entity$getDat = entity.entity.getData(),
          entityContent = _entity$entity$getDat.content; //  - (index * entityContent.length) refers to the change in the content
      // after removing an entity. The entity offsets change after removing one, so the old offset
      // values wouldn't work anymore


      var selectionToRemove = new SelectionState({
        anchorKey: entity.blockKey,
        anchorOffset: entity.start - index * entityContent.length,
        focusKey: entity.blockKey,
        focusOffset: entity.end - index * entityContent.length
      });
      contentWithoutEntities = Modifier.removeRange(contentWithoutEntities, selectionToRemove, 'forward');
    }
  });
  return JSON.stringify(convertToRaw(contentWithoutEntities)).replace(/"text":"[\s]+"/, '"text":""');
}
export function addPlaceholderEntity(text) {
  var label = '[blank]';
  var editorState = getEditorStateFromJSON(text);
  var content = editorState.getCurrentContent();
  var lastBlock = content.getLastBlock();
  var selection = new SelectionState({
    anchorKey: lastBlock.getKey(),
    anchorOffset: lastBlock.getLength(),
    focusKey: lastBlock.getKey(),
    focusOffset: lastBlock.getLength()
  });
  var entityKey = content.createEntity('PLACEHOLDER', 'IMMUTABLE', {
    content: label
  }).getLastCreatedEntityKey();
  var textWithEntity = Modifier.insertText(content, selection, label, null, entityKey);
  var newEditorState = EditorState.push(editorState, textWithEntity, 'insert-characters'); // Get some meta info to check if entity
  // is at the end of a block

  var blockKey = selection.getAnchorKey();
  var offset = selection.getAnchorOffset();
  var currentContentJson = convertToRaw(content);
  var blockToUpdate = currentContentJson.blocks.find(function (block) {
    return block.key === blockKey;
  });

  if (offset === 0) {
    var textWithExtraSpace = Modifier.insertText(newEditorState.getCurrentContent(), selection, ' ');
    newEditorState = EditorState.push(newEditorState, textWithExtraSpace, 'insert-characters');
  } // If the entity is at the end of a block, we need
  // to insert a space to avoid a shitty bug that moves the caret to the
  // far end of the input


  if (blockToUpdate.text.length === offset) {
    var updateSelection = new SelectionState({
      anchorKey: newEditorState.getSelection().getAnchorKey(),
      anchorOffset: newEditorState.getSelection().getAnchorOffset() + label.length,
      focusKey: newEditorState.getSelection().getAnchorKey(),
      focusOffset: newEditorState.getSelection().getFocusOffset() + label.length,
      isBackward: newEditorState.getSelection().getIsBackward(),
      hasFocus: newEditorState.getSelection().getHasFocus()
    });

    var _textWithExtraSpace = Modifier.insertText(newEditorState.getCurrentContent(), updateSelection, ' ');

    newEditorState = EditorState.push(newEditorState, _textWithExtraSpace, 'insert-characters');
  }

  return JSON.stringify(convertToRaw(newEditorState.getCurrentContent()));
}
export function isBlankStringExplicitly(stringToTest) {
  return stringToTest === '' || /^\s*$/.test(stringToTest);
}
export function isBlankString(stringToTest) {
  return !stringToTest || /^\s*$/.test(stringToTest);
}
export function isValidUrl(urlToTest) {
  var isValid = !isBlankString(urlToTest);

  if (isValid) {
    try {
      // eslint-disable-next-line no-new
      new URL(urlToTest);
    } catch (_) {
      isValid = false;
    }
  }

  return isValid;
}
export function convertDraftContentToHTML(content) {
  // We need to consider there will be leftover pure HTML content
  if (isHTML(content)) {
    return content;
  }

  var blockData = getJson(content);

  if (blockData) {
    var editorState = createEditorState(blockData);
    var convertedContent = mediumDraftExporter(editorState.getCurrentContent()); // HTML content converted from editor data

    /**
     *  We test if the final html content isn't simply empty
     * as it returns things like <p><br/></p> on empty lines to the
     * xml exporter which isn't cool
     * */

    return isBlankStringExplicitly(stripHtml(convertedContent)) ? '' : convertedContent;
  }

  return content; // plain text content
}
export function getTextFromTextField(field) {
  var t = Array.isArray(field) ? field[0] : field;
  return convertDraftContentToHTML(t || '');
}

function textIsDraftJs(text) {
  var hasBlocks = text.includes('"blocks":[');
  var hasEntityMap = text.includes('"entityMap":{');
  return hasBlocks && hasEntityMap;
}

function genDraftJsBlockKey() {
  var milliseconds = Date.now().toString();
  var key = milliseconds.substring(milliseconds.length - 5);
  return key;
}

export function getDefaulRichTextContent() {
  var text = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';

  if (text && textIsDraftJs(text)) {
    return text;
  }

  var key = genDraftJsBlockKey();
  return JSON.stringify({
    blocks: [{
      key: key,
      text: text,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: [],
      data: {}
    }],
    entityMap: {}
  });
}
/*
 * Helper functions for Draft.js - END
 */

/*
 * Helper functions for the Database - START
 */

export function reorderIds(originalIds, contentId, afterContentId) {
  var idsWithoutContent = originalIds.filter(function (id) {
    return id !== contentId;
  });

  if (!afterContentId) {
    return [contentId].concat(_toConsumableArray(idsWithoutContent));
  }

  return idsWithoutContent.reduce(function (acc, id) {
    acc.push(id);

    if (id === afterContentId) {
      acc.push(contentId);
    }

    return acc;
  }, []);
}
/*
 * Helper functions for the Database - END
 */

/*
 * Answers
 */

export var getPristineAnswer = function getPristineAnswer() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$type = _ref.type,
      type = _ref$type === void 0 ? INCORRECT : _ref$type,
      _ref$text = _ref.text,
      text = _ref$text === void 0 ? '' : _ref$text,
      _ref$correction = _ref.correction,
      correction = _ref$correction === void 0 ? '' : _ref$correction,
      _ref$units = _ref.units,
      units = _ref$units === void 0 ? [] : _ref$units;

  return {
    type: type,
    text: text,
    correction: correction,
    units: units
  };
};
export var getDefaultPristineAnswers = function getDefaultPristineAnswers() {
  return DEFAULT_ANSWERSET.map(function (type) {
    return getPristineAnswer({
      type: type
    });
  });
};
export var getConjugationPristineAnswers = function getConjugationPristineAnswers() {
  return [getPristineAnswer({
    type: CORRECT
  })];
};
/*
 * Answers - END
 */

/*
 * Tests - START
 */

export var isAutoEvalTestType = function isAutoEvalTestType(type) {
  return type === AUTOEVAL;
};
export var isConjugationTestType = function isConjugationTestType(type) {
  return type === CONJUGATION;
};
export var isConjugationTest = function isConjugationTest(test) {
  return isConjugationTestType(test.type);
};
export var isFibTestType = function isFibTestType(type) {
  return type === FIB;
};
export var isFibTest = function isFibTest(test) {
  return isFibTestType(test.type);
};
export var isBracketTestType = function isBracketTestType(type) {
  return WITH_BRACKETS.includes(type);
};
export var isNoBracketTestType = function isNoBracketTestType(type) {
  return WITHOUT_BRACKETS.includes(type);
};
export var canHaveSwitchCardMode = function canHaveSwitchCardMode(test) {
  return isFibTest(test) || isConjugationTest(test);
};
export var isMediaBracketTest = function isMediaBracketTest(test) {
  return isBracketTestType(test.type) && CUSTOM_LAYOUT_MODE.includes(test.layout);
};
export var isMediaBracketExample = function isMediaBracketExample(test, selectedBracket) {
  return isMediaBracketTest(test) && !get(test, "advancedText[".concat(selectedBracket, "].isExercise"), false);
};
export var defaultAdvancedText = function defaultAdvancedText() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref2$text = _ref2.text,
      text = _ref2$text === void 0 ? '' : _ref2$text,
      _ref2$isExercise = _ref2.isExercise,
      isExercise = _ref2$isExercise === void 0 ? true : _ref2$isExercise,
      _ref2$conjugationForm = _ref2.conjugationFormatting,
      conjugationFormatting = _ref2$conjugationForm === void 0 ? '' : _ref2$conjugationForm,
      _ref2$correctWords = _ref2.correctWords,
      correctWords = _ref2$correctWords === void 0 ? '' : _ref2$correctWords,
      _ref2$idmedia = _ref2.idmedia,
      idmedia = _ref2$idmedia === void 0 ? '' : _ref2$idmedia,
      _ref2$mediaType = _ref2.mediaType,
      mediaType = _ref2$mediaType === void 0 ? IMAGE : _ref2$mediaType,
      _ref2$mediaValue = _ref2.mediaValue,
      mediaValue = _ref2$mediaValue === void 0 ? '' : _ref2$mediaValue;

  return {
    text: text,
    isExercise: isExercise,
    conjugationFormatting: conjugationFormatting,
    correctWords: correctWords,
    media: {
      idmedia: idmedia,
      type: mediaType,
      value: mediaValue
    }
  };
};
/*
 * Tests - END
 */

/*
 * publication-status - START
 */

export function getPublicationStatusConfs() {
  var projectHash = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var insideLesson = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var status = {
    draft: {
      name: 'Draft',
      color: '#9E9E9E'
    },
    proofread: {
      name: 'Proofread',
      color: '#FFD600'
    },
    validated: {
      name: 'Edited',
      color: '#FF9800'
    },
    fixed: {
      name: 'Fixed',
      color: '#4274BD'
    }
  };

  if (canShowTranslatingPublicationStatus(projectHash, insideLesson)) {
    status.translating = {
      name: 'Translation in progress',
      color: '#FF0000'
    };
  }
  /** Put approved status at the end for good rendering order */


  status.approved = {
    name: 'Approved',
    color: '#4CAF50'
  };
  return status;
}
export var isValidPublicationStatus = function isValidPublicationStatus(status) {
  var productHash = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var insideLesson = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return Object.keys(getPublicationStatusConfs(productHash, insideLesson)).includes(status);
};
export var getPublicationStatusColor = function getPublicationStatusColor(status) {
  /** Return the corresponding color for the passed grain status * */
  var conf = getPublicationStatusConfs()[status];

  if (!conf) {
    return 'grey';
  }

  return conf.color;
};
export var getPublicationStatusName = function getPublicationStatusName(status, projectHash, insideLesson) {
  /** Return the corresponding name for the passed grain status * */
  var conf = getPublicationStatusConfs(projectHash, insideLesson)[status];

  if (!conf) {
    return 'None';
  }

  return conf.name;
};
/*
 * publication-status - END
 */

/*
 * Projects - START

  TODO: This is only temporary hard-coded!

  At some point we need to introduce a kind of way to load project configurations
  into the redux store from the Gymglish backend that tell us which "modes" we
  have and what we should show in each mode.

  At the moment the internal Gymglish projects SOCK DE and SOCK ES have to been
  treated as extended projects.
*/

var SOCK_PROJECT_HASHES = [WUNDERBLA_HASH, BORBOLLON_HASH];
export var canShowPlacementTests = function canShowPlacementTests(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var canShowInstructionField = function canShowInstructionField(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var canShowConjugationTests = function canShowConjugationTests(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var canShowCecrName = function canShowCecrName(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var canShowMediaBracketTest = function canShowMediaBracketTest(hash) {
  return [].concat(SOCK_PROJECT_HASHES, [MEMORABLE_HASH, GYMCORSU_HASH]).includes(hash);
};
export var canShowStatusOfGrainTest = function canShowStatusOfGrainTest(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var canShowDoNotTranslateOption = function canShowDoNotTranslateOption(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var canShowTranslatingPublicationStatus = function canShowTranslatingPublicationStatus(hash, insideLesson) {
  return insideLesson && SOCK_PROJECT_HASHES.includes(hash);
};
export var shouldDisableCategoryEdit = function shouldDisableCategoryEdit(hash) {
  return [].concat(SOCK_PROJECT_HASHES, [MEMORABLE_HASH]).includes(hash);
};
export var canShowTagsInput = function canShowTagsInput(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var isSockProject = function isSockProject(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};
export var isMemorableProject = function isMemorableProject(hash) {
  return hash === MEMORABLE_HASH;
};
export var isInStudioV2 = function isInStudioV2(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};

var hasDefaultGrainCategory = function hasDefaultGrainCategory(hash) {
  return SOCK_PROJECT_HASHES.includes(hash);
};

export var getDefaultGrainCategory = function getDefaultGrainCategory(hash, type) {
  if (!hasDefaultGrainCategory(hash)) return null;

  if (type === NUGGET) {
    return 'VOCABULARY';
  }

  return 'GRAMMAR';
};
export var canApproveGrain = function canApproveGrain(coverComponent, coverType) {
  return function () {
    var jsonBlockHasContent = function jsonBlockHasContent(textBlock) {
      if (!textBlock) {
        return false;
      }

      try {
        var jsonBlock = JSON.parse(textBlock);
        return jsonBlock.blocks.some(function (block) {
          return block.text;
        });
      } catch (e) {
        return true;
      }
    };

    var io = coverComponent.io,
        title = coverComponent.title,
        clarifications = coverComponent.clarifications;

    if (coverType === NUGGET) {
      return io.some(function (_ref3) {
        var inputText = _ref3.input.text,
            outputText = _ref3.output.text;
        return jsonBlockHasContent(inputText) && jsonBlockHasContent(outputText);
      });
    }

    if (!title) {
      return false;
    }

    return clarifications.some(function (_ref4) {
      var text = _ref4.text;
      return jsonBlockHasContent(text);
    });
  };
};
/*
 * Projects - END
 */